<template>
    <div>
        <modal
        class="new-modal-default"
        :name="modal_name"
        transition="nice-modal-fade"
        :delay="100"
        :width="650"
        :height="370"
        :pivot-y="0.5"
        :adaptive="true"
        :scrollable="true"
        >
            <div class="v-modal-content" style="background-color: #ffffff !important;">
                <div class="v-modal-header">
                    <div></div>
                    <div>
                        <span class="v-modal-dialog-title v-modal-title">VIEW LINK HERE</span>
                    </div>
                    <div>
                        <span class="icon icon-close mr-2 text-white pointer close-left" @click="hideSelf"></span>
                    </div>
                </div>
                <div class="v-modal-body overflow-hidden">
                    <div class="v-modal-layout">
                        <div class="d-flex align-items-center justify-content-between flex-column mt-4 px-8">
                            <img :src="socialMediaType" style="height: 5em; width: 5em">
                            <div class="d-flex flex-column w-100">
                                <span class="text-secondary fs-14 fw-600">User ID</span>
                                <span class="inputFieldNew mt-4">{{ uid }}</span>
                            </div>
                            <div class="d-flex flex-column w-100 mb-4 mt-8">
                                <span class="text-secondary fs-14 fw-600">URL</span>
                                <span class="inputFieldNew mt-4">
                                    <a :href="url" target="_blank">{{ url }}</a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
    props: ["modal_name","socialMediaType","url","uid"],
    methods: {
        hideSelf() {
            this.$modal.hide(this.modal_name);
            this.$emit('hideSocialMedia');
        },
    }
}
</script>